<template>
  <div class="thumb--news">
    <div class="thumb--news__header">
      <h3 class="trim--sans">
        {{ data.title.rendered }}
      </h3>
      <h5 class="sans--h5--alt trim--sans">
        {{ date }}
      </h5>
    </div>
    <p
      class="trim--serif--text"
      v-html="data.acf.text"
    />
  </div>
</template>

<script>
export default {
  name: 'Thumb',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    date() {
      const date = new Date(this.data.date);
      const day = date.getDate();
      const months = [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
      ];
      const month = months[date.getMonth()];
      const year = date.getFullYear();
      return `${day} ${month} ${year}`;
    },
  },
};
</script>

<style lang="scss">
  .thumb {
    &--news {
      align-content: space-between;
      background: var(--white);
      border-radius: var(--border-radius);
      display: grid;
      padding: var(--spacer-xs);
      padding-bottom: var(--spacer-m);
      width: 100%;

      @include mq(s) {
        width: 500px;
      }

      &__header {
        display: grid;
        row-gap: var(--row-gap-xs);
      }
    }
  }
</style>

<template>
  <figure>
    <img
      ref="image"
      :data-src="data.lazyload || skipLoad ? src : null"
      :src="!data.lazyload && !skipLoad ? src : ''"
      :alt="defaultImage.alt"
    >
  </figure>
</template>

<script>
export default {
  name: 'Figure',
  props: {
    data: {
      type: Object,
      required: true,
    },
    skipLoad: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      currentImg: 'default',
      src: null,
      ratio: 0,
    };
  },
  computed: {
    defaultImage() {
      if (this.data.default) {
        return this.data.default;
      }
      return this.data;
    },
    imageType() {
      if (this.data.type === 'svg') {
        return 'inline-svg';
      }
      if (this.defaultImage?.subtype === 'svg+xml') {
        return 'svg';
      }
      return 'image';
    },
  },
  mounted() {
    if (this.imageType === 'image') {
      this.currentSrc();
      this.setRatio();
      this.$bus.$on('windowResized', this.currentSrc);
    }

    if (!this.data.lazyload && !this.skipLoad) {
      this.load();
    }
  },
  beforeDestroy() {
    if (this.imageType === 'image') {
      this.$bus.$off('windowResized', this.currentSrc);
    }
  },
  methods: {
    currentSrc() {
      let src = this.defaultImage.sizes.l;
      const { sizes } = this.defaultImage;
      const ratio = window.devicePixelRatio >= 2 ? 2 : 1;
      const wrapper = this.$parent.$el.offsetWidth;
      const dimension = 'width';
      const max = wrapper >= sizes[`xxl-${dimension}`] ? wrapper : wrapper * ratio;
      const ranges = {
        xxs: sizes[`xxs-${dimension}`],
        xs: sizes[`xs-${dimension}`],
        s: sizes[`s-${dimension}`],
        m: sizes[`m-${dimension}`],
        l: sizes[`l-${dimension}`],
        xl: sizes[`xl-${dimension}`],
        xxl: sizes[`xxl-${dimension}`],
      };

      const sizesRange = Object.keys(ranges).filter(
        (key) => ranges[key] >= max,
      );
      const size = sizesRange.length > 0 ? sizesRange[0] : 'l';

      this.$nextTick(() => {
        if (this.$mq.isMobile && this.data.mobile) {
          src = this.data.mobile.sizes[size];
          this.currentImg = 'mobile';
          this.setRatio();
        } else {
          src = this.defaultImage.sizes[size];
          this.currentImg = 'default';
          this.setRatio();
        }

        if (this.$el.dataset.lazy === 'true') {
          this.$refs.image.src = src;
        }

        this.src = src;
      });
    },
    setRatio() {
      if (!this.data && !this.defaultImage) this.ratio = 0;
      if (this.data.ratio && typeof this.data.ratio === 'number') {
        this.ratio = this.data.ratio * 100;
      } else {
        this.ratio = this.currentImg === 'default'
          ? (this.defaultImage.height / this.defaultImage.width) * 100
          : (this.data.mobile.height / this.data.mobile.width) * 100;
      }
    },
    async load() {
      const img = this.$refs.image;

      if (this.skipLoad && this.src) {
        img.src = this.src;
      }

      if (img && img.decode && img.src) {
        await img.decode();
        this.loaded = true;
      } else {
        this.loaded = true;
      }
    },
  },
};
</script>

<style lang="scss">

</style>

<template>
  <div class="about__container">
    <main class="about">
      <div class="about__header">
        <h1 class="trim--sans">
          {{ about.header.main }}
        </h1>
        <div
          class="about__header__sub serif--p1"
          v-html="about.header.sub"
        />
      </div>
      <div class="about__body">
        <div
          v-for="(section, index) in about.body.sections"
          :key="index"
          class="about__body__section"
        >
          <h1 class="about__body__section__title trim--sans">
            {{ section.section.title }}
          </h1>
          <div
            class="about__body__section__text"
            v-html="section.section.text"
          />
        </div>
        <div class="about__body__gaps" />
      </div>
      <div class="about__footer">
        <div class="about__footer__sections">
          <div
            v-for="(column, index) in about.footer.columns"
            :key="index"
          >
            <div
              v-for="(section, index) in column.column.sections"
              :key="index"
            >
              <h6>
                {{ section.section.title }}
              </h6>
              <h4>{{ section.section.text }}</h4>
            </div>
          </div>
        </div>
        <div>
          <Figure
            class="about__footer__logo"
            :data="{ ...about.footer.logo, ratio: true }"
          />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import data from '@/mixins/data';
import Figure from '@/components/media/figure';

export default {
  name: 'About',
  components: {
    Figure,
  },
  mixins: [data],
  computed: {
    about() {
      return this.post.acf.about;
    },
  },
};
</script>

<style lang="scss">
  .about {
    &__container {
      align-content: end;
      display: grid;
      height: calc(var(--vh) * 100);
      position: fixed;
      width: 100%;
      z-index: 2;
    }

    background: var(--white);
    height: inherit;
    overflow: auto;

    &__header {
      background: var(--blue);
      padding-top: var(--header-height);

      h1 {
        padding: var(--spacer-l) var(--spacer-xs);
        color: var(--white);
      }

      &__sub {
        padding: var(--spacer-m) var(--spacer-xs);
        background: var(--white);
        margin-bottom: var(--spacer-s);
      }
    }

    &__body {
      background: var(--grey);
      gap: 0;
      position: relative;
      z-index: 0;
      // max-width: 100vw;

      @include mq(s) {
        columns: auto 2;
      }

      &__section {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;

        &__title {
          background: var(--grey);
          border-left: var(--column-gap-xs) var(--blue) solid;
          padding: var(--spacer-xs);
          color: var(--blue);
        }

        &__text {
          background: var(--white);
          padding: var(--spacer-s) var(--spacer-xs);
          padding-bottom: var(--spacer-xl);
          border-left: var(--column-gap-xs) var(--grey) solid;
        }

        &:nth-last-child(2) {
          padding-bottom: var(--spacer-xl);
        }
      }

      &__gaps {
        background: var(--white);
        display: grid;
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        @include mq(s) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        &::after,
        &::before {
          border-left: var(--column-gap-xs) var(--grey) solid;
          content: "";
        }
      }
    }

    &__footer {
      display: grid;
      background: var(--grey);
      justify-items: start;
      padding-top: calc(var(--spacer-xl) * 2 - var(--spacer-m));

      @include mq(s) {
        padding-top: 0;
      }

      &__sections {
        padding: var(--spacer-m) 0;
        row-gap: var(--row-gap-s);
        grid-auto-columns: minmax(0, 1fr);
        display: grid;

        @include mq(s) {
          grid-auto-flow: column;
        }

        > * {
          display: grid;
          row-gap: var(--row-gap-s);
          align-content: start;
          padding-left: calc(var(--spacer-xs) + var(--column-gap-xs));

          > * {
            display: grid;
            row-gap: var(--row-gap-xxs);

            h4 {
              white-space: pre-line;
            }
          }
        }
      }

      h6 {
        text-transform: uppercase;
      }

      &__logo.picture {
        display: grid;
        padding: calc(var(--spacer-xs) + var(--column-gap-xs));

        img {
          width: 160px;
        }
      }
    }
  }
</style>

<template>
  <main class="main--list">
    <h1 class="sans--h1--shadow">
      {{ $labels.archive_name }}
    </h1>
    <div class="main--list__filters">
      <div
        class="triangled"
        @click="toggleFilters()"
      >
        <h5 class="sans--h5--alt trim--sans">
          {{ $labels.filters }}
        </h5>
        <div
          :class="['triangle', filtersAreVisible ? 'triangle--reverse' : false]"
        />
      </div>
      <div v-show="filtersAreVisible">
        <Cta
          v-for="phase in phases"
          :key="phase.id"
          :theme="activePhase === phase.id ? 'filter--active' : 'filter'"
          :data="{
            fn: () => filterItems(phase),
            title: phase.name,
          }"
        />
      </div>
    </div>
    <Archive
      theme="list"
      :active-phase="activePhase"
      :data="{
        archive_type: 'archive',
        layout: '',
        archive: 'case_study',
        thumb: 'list',
      }"
    />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';

import Archive from '@/components/blocks/archive.vue';
import Cta from '@/components/typo/cta';

export default {
  name: 'List',
  components: { Archive, Cta },
  data() {
    return {
      activePhase: false,
      filtersAreVisible: true,
    };
  },
  computed: {
    ...mapGetters(['phases']),
  },
  watch: {
    $mq: {
      deep: true,
      handler() {
        if (this.$mq.isMobileS) {
          this.filtersAreVisible = false;
        } else {
          this.filtersAreVisible = true;
        }
      },
    },
  },
  mounted() {
    if (this.$mq.isMobileS) {
      this.filtersAreVisible = false;
    }
  },
  methods: {
    toggleFilters() {
      this.filtersAreVisible = !this.filtersAreVisible;
    },
    filterItems(phase) {
      if (this.activePhase === phase.id) {
        this.activePhase = false;
      } else this.activePhase = phase.id;
    },
  },
};
</script>

<style lang="scss">
  .main {
    &--list {
      align-content: start;
      background: var(--grey);
      display: grid;
      row-gap: var(--row-gap-m);
      padding: var(--spacer-xs);
      padding-top: calc(var(--spacer-xs) + var(--header-height));
      min-height: calc(var(--vh) * 100);
      width: 100%;

      h1 {
        margin-top: var(--spacer-xs);
        text-align: center;
      }

      &__filters {
        display: grid;
        row-gap: var(--row-gap-xs);

        .triangled {
          @include mq(s) {
            display: none;
          }
        }

        > :last-child {
          display: flex;
          gap: var(--column-gap-s);
          flex-wrap: wrap;
        }
      }
    }
  }
</style>

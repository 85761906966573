<template>
  <div
    ref="container"
    class="news__container"
    tabindex="-1"
    @keydown.esc="close"
  >
    <div
      ref="news"
      class="news"
    >
      <div class="news__header">
        <h1 class="sans--h1--shadow trim--sans">
          {{ $labels.news_name }}
        </h1>
        <Cta
          theme="nav--inverse"
          :data="{
            fn: close,
            title: $labels.close_cta,
          }"
        />
      </div>
      <Archive
        theme="news"
        :data="{
          archive_type: 'archive',
          layout: '',
          archive: 'news',
        }"
      />
    </div>
  </div>
</template>

<script>
import Archive from '@/components/blocks/archive.vue';
import Cta from '@/components/typo/cta';

export default {
  name: 'News',
  components: {
    Archive,
    Cta,
  },
  mounted() {
    this.$bus.$emit('removeCasesListener');
    window.addEventListener('click', this.handleClick);
    this.$refs.container.focus();
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClick);
  },
  methods: {
    handleClick(event) {
      if (!this.$refs.news.contains(event.target)) {
        this.close();
      }
    },
    close() {
      this.$bus.$emit('addCasesListener');
      this.$router.push({ name: 'Home' });
    },
  },
};
</script>

<style lang="scss">
  .news {
    &__container {
      align-content: end;
      display: grid;
      height: calc(var(--vh) * 100);
      position: fixed;
      width: 100%;
      z-index: 4;
      cursor: url("~@/assets/svg/icons/close.svg"), auto;

      @include mq(s) {
        align-content: center;
        padding: var(--spacer-xs);
      }
    }

    align-content: start;
    background: var(--blue);
    cursor: auto;
    display: grid;
    grid-auto-columns: minmax(0, auto);
    grid-template-rows: auto minmax(400px, auto);
    grid-auto-rows: 1fr;
    height: calc(var(--vh) * 100);
    justify-content: start;
    overflow: auto;
    padding: var(--spacer-xs) 0;
    padding-top: var(--header-height);
    pointer-events: all;
    row-gap: var(--row-gap-m);
    width: 100%;

    @include mq(s) {
      border-radius: var(--border-radius);
      height: initial;
      padding: var(--spacer-xs);
    }

    &__header {
      display: grid;
      justify-items: center;
      width: 100%;

      > * {
        grid-area: 1 / 1;
      }

      .cta {
        bottom: var(--spacer-xs);
        display: grid;
        justify-content: center;
        position: fixed;
        width: 100%;

        @include mq(s) {
          position: initial;
          place-self: start;
          width: initial;
        }
      }
    }

    h1 {
      text-align: center;
    }
  }
</style>

<template>
  <div
    v-show="!$route.meta.hideHeader"
    :class="['header', overlay ? 'header--overlay' : false]"
    :style="color && control ? { backgroundColor: `var(--${color})` } : false"
  >
    <Menu :theme="color ? color : ''" />
  </div>
</template>

<script>
import Menu from '@/components/ui/menu';

export default {
  name: 'Header',
  components: {
    Menu,
  },
  props: {
    transitionStart: {
      type: Boolean,
      default: true,
    },
    cases: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      casesTiming: false,
    };
  },
  computed: {
    color() {
      let backgroundColor = '';
      const { color } = this.$route.meta;
      const { mobileColor } = this.$route.meta;

      if (this.$mq.isMobileS) {
        if (color) {
          backgroundColor = color;
        }
        if (mobileColor) {
          backgroundColor = mobileColor;
        }
      } else if (color) {
        backgroundColor = color;
      }

      return backgroundColor;
    },
    control() {
      if (this.$route.name === 'Cases') {
        return this.casesTiming;
      }
      return this.transitionStart;
    },
    overlay() {
      if (this.cases) {
        return false;
      }
      if (
        this.$route.name === 'Case-case_study'
          || this.$route.name === 'News'
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    $route(route) {
      if (route.name === 'Cases') {
        setTimeout(() => {
          this.casesTiming = true;
        }, 400);
      } else {
        this.casesTiming = false;
      }
    },
  },
};
</script>

<style lang="scss">
  .header {
    align-content: end;
    display: grid;
    min-height: var(--header-height);
    padding: var(--spacer-xs);
    position: fixed;
    top: 0;
    // transition: background-color var(--timing) ease;
    width: 100%;
    z-index: 5;

    @include mq(s) {
      z-index: 3;
    }

    &--overlay {
      cursor: url("~@/assets/svg/icons/close.svg"), auto;

      @include mq(s) {
        z-index: 5;
      }
    }
  }
</style>

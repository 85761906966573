<template>
  <nav class="menu">
    <Cta
      :theme="theme === 'blue' ? 'nav--blue' : 'nav'"
      :data="{
        url: headerMenu[0].url,
        title: headerMenu[0].content,
        target: headerMenu[0].target,
      }"
    />
    <Cta
      :theme="theme === 'blue' ? 'nav--blue' : 'nav'"
      :data="{
        url: listView ? { name: 'Cases' } : { name: 'Home' },
        title: headerMenu[1].content,
        target: headerMenu[1].target,
      }"
    />
    <Cta
      :theme="theme === 'blue' ? 'nav--blue' : 'nav'"
      :data="{
        url: headerMenu[2].url,
        title: headerMenu[2].content,
        target: headerMenu[2].target,
      }"
    />
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';

export default {
  name: 'Menu',
  components: {
    Cta,
  },
  props: {
    theme: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['menu', 'listView']),
    headerMenu() {
      const nav = this.menu('Header');
      return nav ? nav.items : null;
    },
  },
};
</script>

<style lang="scss">
  .menu {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;

    .cta:nth-child(2) .router-link-exact-active {
      color: inherit;
    }
  }
</style>

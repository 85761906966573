<template>
  <div
    id="app"
    ref="app"
  >
    <AppHeader
      :cases="cases"
      :transition-start="transitionStart"
    />

    <transition
      appear
      mode="out-in"
      name="fade"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
    >
      <router-view :key="$route.path" />
    </transition>
    <transition
      :name="listViewPromise ? 'horizontal-slide-in' : 'horizontal-slide-out'"
      @before-enter="lockHome"
      @after-enter="unlockHome"
      @before-leave="beforeLeave"
    >
      <keep-alive include="Cycle">
        <component
          :is="listViewPromise || $mq.isMobileS ? 'List' : 'Cycle'"
          @open-cases="openCases"
        />
      </keep-alive>
    </transition>
    <ViewToggle />
    <transition
      appear
      mode="out-in"
      name="fade"
    >
      <Cases
        v-if="cases"
        :phase="activePhase"
        :sub="sub"
        @close="closeCases"
      />
    </transition>
    <Cookie v-if="options && options.cookies" />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import debounce from 'lodash.debounce';
import medusa from '@/assets/js/observer';
import lazyload from '@/mixins/lazyload';
import AppHeader from '@/components/ui/header';
import Cycle from '@/views/Cycle';
import List from '@/views/List';
import Cases from '@/views/Cases';
import ViewToggle from '@/components/ui/view-toggle';

import Cookie from '@/components/ui/cookie';

export default {
  name: 'App',
  components: {
    AppHeader,
    Cookie,
    Cycle,
    ViewToggle,
    List,
    Cases,
  },
  mixins: [lazyload],
  data() {
    return {
      listViewPromise: this.$route.name === 'Cases',
      activePhase: false,
      route: {},
      sub: false,
      transitionStart: true,
    };
  },
  computed: {
    ...mapGetters(['currentPost', 'options', 'listView', 'cases']),
  },
  watch: {
    listView: {
      handler(value) {
        if (value) {
          this.$router.replace({ name: 'Cases' }).then(() => {
            this.listViewPromise = true;
          });
        } else if (this.$route.name !== 'Home') {
          this.$router.replace({ name: 'Home' }).then(() => {
            this.listViewPromise = false;
          });
        }
      },
    },
    $route(route) {
      if (route.name === 'About') {
        this.activePhase = false;
      }
    },
  },
  created() {
    medusa.init();
  },
  mounted() {
    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    window.addEventListener(
      'resize',
      debounce(() => {
        this.$bus.$emit('windowResized');
        Vue.set(Vue.prototype, '$mq', this.$mq.setMq());
        this.$mq.vh();
        if (this.$mq.isMobileS && this.listView === false) {
          this.TOGGLE_VIEW(true);
        }
      }, 0),
    );
  },
  methods: {
    ...mapMutations(['TOGGLE_VIEW', 'TOGGLE_CASES']),
    openCases(phase, sub) {
      this.activePhase = phase;
      this.TOGGLE_CASES(true);
      this.sub = sub;
    },
    closeCases() {
      this.TOGGLE_CASES(false);
      setTimeout(() => {
        this.activePhase = false;
      }, 100);
    },
    afterEnter() {
      this.transitionStart = true;
    },
    beforeLeave() {
      this.transitionStart = false;
    },
    lockHome() {
      this.$refs.app.style.overflow = 'hidden';
    },
    unlockHome() {
      this.$refs.app.removeAttribute('style');
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/style.scss";

  .app-loaded {
    #loader {
      display: none;
    }
  }
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"menu"},[_c('Cta',{attrs:{"theme":_vm.theme === 'blue' ? 'nav--blue' : 'nav',"data":{
      url: _vm.headerMenu[0].url,
      title: _vm.headerMenu[0].content,
      target: _vm.headerMenu[0].target,
    }}}),_c('Cta',{attrs:{"theme":_vm.theme === 'blue' ? 'nav--blue' : 'nav',"data":{
      url: _vm.listView ? { name: 'Cases' } : { name: 'Home' },
      title: _vm.headerMenu[1].content,
      target: _vm.headerMenu[1].target,
    }}}),_c('Cta',{attrs:{"theme":_vm.theme === 'blue' ? 'nav--blue' : 'nav',"data":{
      url: _vm.headerMenu[2].url,
      title: _vm.headerMenu[2].content,
      target: _vm.headerMenu[2].target,
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"news__container",attrs:{"tabindex":"-1"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}}},[_c('div',{ref:"news",staticClass:"news"},[_c('div',{staticClass:"news__header"},[_c('h1',{staticClass:"sans--h1--shadow trim--sans"},[_vm._v(" "+_vm._s(_vm.$labels.news_name)+" ")]),_c('Cta',{attrs:{"theme":"nav--inverse","data":{
          fn: _vm.close,
          title: _vm.$labels.close_cta,
        }}})],1),_c('Archive',{attrs:{"theme":"news","data":{
        archive_type: 'archive',
        layout: '',
        archive: 'news',
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }